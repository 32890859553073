import { TeaserGridSection } from 'components/UI/TeaserGridSectionNew';
import { TeaserNew } from 'components/UI/TeaserNew';
import type { WidgetTeaserStandardProps } from 'types/cms-teaser';
import { linkUrl } from 'utils/url';

const WidgetTeaserColor = ({ context }: { context: WidgetTeaserStandardProps }) => {
  const width = 663;

  return (
    <TeaserGridSection
      title={context.model?.fields?.title}
      titleLink={context.model?.fields?.link}
      anchor={context.model?.fields?.anchor}
      variant="color"
    >
      {context.items?.map((item) => (
        <TeaserNew
          key={item.model.id}
          variant="color"
          title={item.model.fields.title}
          subtitle={item.model.themenwelt?.section_name}
          image={{ image: item.model.image, width: width, alt: `Bild zu: "${item.model.fields.title}"` }}
          linkHref={linkUrl(item.model.url)}
          backgroundColor={item.model.themenwelt?.secondary_color}
          isVideo={!!item.model.fields.encoding?.[0]?.value?.duration?.value}
          videoDuration={Number(item.model.fields.encoding?.[0]?.value?.duration?.value)}
          isSponsored={item.type === 'sponsored-article'}
        />
      ))}
    </TeaserGridSection>
  );
};

export default WidgetTeaserColor;
